<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <path
        style="fill:#D5DCED;"
        d="M373.153,0h-17.356c-4.797,0-8.678,3.886-8.678,8.678s3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.886,8.678-8.678S377.95,0,373.153,0z"
      />
      <path
        style="fill:#D5DCED;"
        d="M269.017,0h-17.356c-4.797,0-8.678,3.886-8.678,8.678s3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.886,8.678-8.678S273.814,0,269.017,0z"
      />
      <path
        style="fill:#D5DCED;"
        d="M216.949,0h-26.034c-4.797,0-8.678,3.886-8.678,8.678v26.034c0,4.792,3.881,8.678,8.678,8.678
		c4.797,0,8.678-3.886,8.678-8.678V17.356h17.356c4.797,0,8.678-3.886,8.678-8.678S221.746,0,216.949,0z"
      />
      <path
        style="fill:#D5DCED;"
        d="M321.085,0h-17.356c-4.797,0-8.678,3.886-8.678,8.678s3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.886,8.678-8.678S325.882,0,321.085,0z"
      />
      <path
        style="fill:#D5DCED;"
        d="M425.221,0h-17.356c-4.797,0-8.678,3.886-8.678,8.678s3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.886,8.678-8.678S430.017,0,425.221,0z"
      />
      <path
        style="fill:#D5DCED;"
        d="M373.153,295.051h-17.356c-4.797,0-8.678,3.881-8.678,8.678c0,4.797,3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.881,8.678-8.678C381.831,298.932,377.95,295.051,373.153,295.051z"
      />
      <path
        style="fill:#D5DCED;"
        d="M269.017,295.051h-17.356c-4.797,0-8.678,3.881-8.678,8.678c0,4.797,3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.881,8.678-8.678C277.695,298.932,273.814,295.051,269.017,295.051z"
      />
      <path
        style="fill:#D5DCED;"
        d="M321.085,295.051h-17.356c-4.797,0-8.678,3.881-8.678,8.678c0,4.797,3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.881,8.678-8.678C329.763,298.932,325.882,295.051,321.085,295.051z"
      />
      <path
        style="fill:#D5DCED;"
        d="M425.221,295.051h-17.356c-4.797,0-8.678,3.881-8.678,8.678c0,4.797,3.881,8.678,8.678,8.678h17.356
		c4.797,0,8.678-3.881,8.678-8.678C433.899,298.932,430.017,295.051,425.221,295.051z"
      />
      <path
        style="fill:#D5DCED;"
        d="M485.966,164.881c-4.797,0-8.678,3.881-8.678,8.678v17.356c0,4.797,3.881,8.678,8.678,8.678
		c4.797,0,8.678-3.881,8.678-8.678v-17.356C494.644,168.763,490.763,164.881,485.966,164.881z"
      />
      <path
        style="fill:#D5DCED;"
        d="M485.966,60.746c-4.797,0-8.678,3.886-8.678,8.678V86.78c0,4.792,3.881,8.678,8.678,8.678
		c4.797,0,8.678-3.886,8.678-8.678V69.424C494.644,64.631,490.763,60.746,485.966,60.746z"
      />
      <path
        style="fill:#D5DCED;"
        d="M485.966,112.814c-4.797,0-8.678,3.886-8.678,8.678v17.356c0,4.792,3.881,8.678,8.678,8.678
		c4.797,0,8.678-3.886,8.678-8.678v-17.356C494.644,116.699,490.763,112.814,485.966,112.814z"
      />
      <path
        style="fill:#D5DCED;"
        d="M190.916,199.593c4.797,0,8.678-3.881,8.678-8.678v-17.356c0-4.797-3.881-8.678-8.678-8.678
		c-4.797,0-8.678,3.881-8.678,8.678v17.356C182.238,195.712,186.119,199.593,190.916,199.593z"
      />
      <path
        style="fill:#D5DCED;"
        d="M190.916,254.915c4.797,0,8.678-3.881,8.678-8.678v-20.61c0-4.797-3.881-8.678-8.678-8.678
		c-4.797,0-8.678,3.881-8.678,8.678v20.61C182.238,251.034,186.119,254.915,190.916,254.915z"
      />
      <path
        style="fill:#D5DCED;"
        d="M190.916,95.458c4.797,0,8.678-3.886,8.678-8.678V69.424c0-4.792-3.881-8.678-8.678-8.678
		c-4.797,0-8.678,3.886-8.678,8.678V86.78C182.238,91.572,186.119,95.458,190.916,95.458z"
      />
      <path
        style="fill:#D5DCED;"
        d="M190.916,147.525c4.797,0,8.678-3.886,8.678-8.678v-17.356c0-4.792-3.881-8.678-8.678-8.678
		c-4.797,0-8.678,3.886-8.678,8.678v17.356C182.238,143.64,186.119,147.525,190.916,147.525z"
      />
      <path
        style="fill:#D5DCED;"
        d="M485.966,216.949c-4.797,0-8.678,3.881-8.678,8.678v17.356c0,4.797,3.881,8.678,8.678,8.678
		c4.797,0,8.678-3.881,8.678-8.678v-17.356C494.644,220.83,490.763,216.949,485.966,216.949z"
      />
      <path
        style="fill:#D5DCED;"
        d="M485.966,0h-26.034c-4.797,0-8.678,3.886-8.678,8.678s3.881,8.678,8.678,8.678h17.356v17.356
		c0,4.792,3.881,8.678,8.678,8.678c4.797,0,8.678-3.886,8.678-8.678V8.678C494.644,3.886,490.763,0,485.966,0z"
      />
      <path
        style="fill:#D5DCED;"
        d="M485.966,269.017c-4.797,0-8.678,3.881-8.678,8.678v17.356h-17.356
		c-4.797,0-8.678,3.881-8.678,8.678c0,4.797,3.881,8.678,8.678,8.678h26.034c4.797,0,8.678-3.881,8.678-8.678v-26.034
		C494.644,272.898,490.763,269.017,485.966,269.017z"
      />
    </g>
    <path
      style="fill:#FFDC64;"
      d="M100.78,510.631c26.666-5.208,48.244-26.483,53.844-53.07c4.968-23.586-2.14-45.561-16.159-61.221
	l169.129-253.696c10.634-15.951,6.324-37.502-9.627-48.136L109.593,377.067c-12.695-4.423-26.962-5.477-41.966-1.193
	c-25.598,7.31-45.32,29.264-49.406,55.569C10.769,479.431,52.516,520.055,100.78,510.631z M43.39,442.576
	c0-23.963,19.427-43.39,43.39-43.39s43.39,19.427,43.39,43.39c0,23.963-19.427,43.39-43.39,43.39S43.39,466.539,43.39,442.576z"
    />
    <path
      style="fill:#FFC850;"
      d="M203.585,236.08l-54.213,81.32c7.053,9.418,17.138,16.3,28.874,19.265l54.213-81.319
	C225.406,245.928,215.321,239.044,203.585,236.08z"
    />
    <path
      style="fill:#FFDC64;"
      d="M363.608,431.444c-4.086-26.305-23.808-48.259-49.406-55.569c-15.002-4.285-29.271-3.23-41.966,1.193
	l-50.288-75.433c2.28-4.624,3.678-9.758,3.678-15.262c0-19.171-15.541-34.712-34.712-34.712c-0.732,0-1.418,0.171-2.139,0.216
	L83.864,94.508c-15.951,10.634-20.261,32.184-9.627,48.136l85.644,128.466c-2.279,4.623-3.677,9.758-3.677,15.262
	c0,19.171,15.541,34.712,34.712,34.712c0.732,0,1.418-0.171,2.139-0.216l50.314,75.47c-14.019,15.66-21.128,37.636-16.159,61.221
	c5.599,26.587,27.177,47.862,53.844,53.07C329.314,520.055,371.063,479.431,363.608,431.444z M295.051,485.966
	c-23.963,0-43.39-19.427-43.39-43.39c0-23.963,19.427-43.39,43.39-43.39c23.963,0,43.39,19.427,43.39,43.39
	C338.441,466.539,319.014,485.966,295.051,485.966z"
    />
    <circle style="fill:#E1A546;" cx="190.916" cy="286.373" r="13.017" />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
